import { createSlice } from "@reduxjs/toolkit";

export const filtersSlice = createSlice({
  name: "Filters",
  initialState: {
    title: "",
    priceValues: [],
    category: [],
    paginationPage: 1,
  },
  reducers: {
    setTitleFilter: (state, action) => {
      state.title = action.payload;
    },
    setCategoryFilter: (state, action) => {
      state.category = action.payload;
    },
    setPriceValuesFilter: (state, action) => {
      state.priceValues = action.payload;
    },
    setPaginationPage: (state, action) => {
      state.paginationPage = action.payload;
    },
  },
});

export const {
  setTitleFilter,
  setCategoryFilter,
  setPriceValuesFilter,
  setPaginationPage,
} = filtersSlice.actions;

export default filtersSlice.reducer;
