import { useState } from "react";
import { LayoutGroup, AnimatePresence, motion } from "framer-motion";

const ProductImageThumb = ({ id, onExpand }) => {
  return (
    <motion.img
      src={`/images/${id}`}
      alt=""
      onClick={() => onExpand(id)}
      className="related-product-image"
      layoutId={`product-${id}`}
    />
  );
};

const ProductImagesGallery = ({ images }) => {
  const [primaryProduct, setPrimaryProduct] = useState(images[0]);
  const [productIds, setProductIds] = useState([
    ...images.filter((image) => image !== primaryProduct),
  ]);

  function setAsPrimary(id) {
    const currentProductId = primaryProduct;

    const newProductIds = [
      ...productIds.filter((x) => x !== id),
      currentProductId,
    ];

    setPrimaryProduct(id);
    setProductIds(newProductIds);
  }
  return (
    <div className="flex flex-col w-full md:h-[80vh] md:mb-3 mb-5">
      {/* <ul
          onWheel={(e) => e.stopPropagation()}
          className="flex md:h-96 h-64 flex-grow-1 flex-nowrap overflow-x-scroll whitespace-nowrap snap snap-x snap-mandatory no-scrollbar scroll-behavior-smooth pb-6 "
          >
          {images.map((image) => (
              <li
              key={image}
              className="w-full h-full flex flex-shrink-0 snap-start justify-end items-end  overflow-hidden"
              >
              <img
              src={`/images/${image}`}
              className="m-auto h-full  max-w-full"
              alt=""
              />
            </li>
            ))}
        </ul> */}
      <LayoutGroup>
        <main className="relative w-full h-[50vh]  bg-white md:border-r-4 md:border-my-yellow overflow-hidden">
          <AnimatePresence>
            <motion.img
              key={primaryProduct}
              className="absolute top-0 left-0 w-full h-full object-cover"
              src={`/images/${primaryProduct}`}
              alt=""
              layoutId={`product-${primaryProduct}`}
            />
          </AnimatePresence>
        </main>
        {/* flex-grow-1 flex-nowrap overflow-x-scroll whitespace-nowrap snap snap-x
        snap-mandatory no-scrollbar scroll-behavior-smooth */}
        <div className="w-full flex flex-row justify-start items-start">
          <AnimatePresence>
            {productIds?.map((id) => (
              <motion.div
                key={id}
                className="md:w-28 w-16 cursor-pointer object-contain"
              >
                <ProductImageThumb id={id} onExpand={setAsPrimary} />
              </motion.div>
            ))}
          </AnimatePresence>
        </div>
      </LayoutGroup>
    </div>
  );
};

export default ProductImagesGallery;
