import { motion } from "framer-motion";
export default function Promo() {
  const variants = {
    open: {
      transition: { staggerChildren: 0.07, delayChildren: 0.2 },
    },
    closed: {
      transition: { staggerChildren: 0.05, staggerDirection: -1 },
    },
  };
  return (
    <motion.div className="md:flex hidden absolute bottom-4 items-end justify-between w-full h-1/4 p-2 text-2xl font-bold z-10">
      <motion.div
        initial={{ y: -200, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ duration: 0.9, delay: 0.2, type: "spring" }}
        className="relative flex flex-col items-start justify-start p-4 w-1/3 h-full bg-[brown]  rounded-3xl mx-4  cursor-pointer"
        whileHover={{
          transition: { duration: 0.3 },
          height: "50vh",
        }}
      >
        <h2 className="text-6xl font-thin  text-white my-1">BESTSELLER</h2>
        <h3 className="text-[1rem] font-semibold my-2  text-my-green">
          Bardzo intratna propozycja
        </h3>
        <p className="w-9/12 text-sm text-white">
          {" "}
          Wyższe założenie ideowe, a szczególnie zmiana istniejących kryteriów
          pomaga w większym
        </p>
      </motion.div>
      <motion.div
        initial={{ y: -200, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ duration: 0.9, delay: 0.1, type: "spring" }}
        className="relative flex flex-col items-start justify-start p-4 w-1/3 h-full border-red-900 border-dotted border-4 rounded-3xl mx-4"
        whileHover={{
          transition: { duration: 0.3 },
          height: "50vh",
        }}
      >
        <h2 className="text-6xl font-thin  text-[brown] my-1">RABAT</h2>
        <h3 className="text-[1rem] font-semibold my-2  text-[brown]">
          Bardzo intratna propozycja
        </h3>
        <p className="w-9/12 text-sm text-my-dark-green02">
          {" "}
          Wyższe założenie ideowe, a szczególnie zmiana istniejących kryteriów
          pomaga w większym
        </p>
        <img
          src="./images/bong01.png"
          alt=""
          className="absolute right-4 top-1 md:w-2/12  w-3/12"
        />
      </motion.div>
      <motion.div
        initial={{ y: -200, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ duration: 0.9, ease: "easeInOut" }}
        className="relative flex flex-col items-start justify-start p-4 w-1/3 h-full bg-my-dark-green  rounded-3xl mx-4 overflow-hidden  cursor-pointer"
        whileHover={{
          transition: { duration: 0.3 },
          height: "50vh",
        }}
      >
        <h2 className="text-6xl font-thin  text-white my-1">BESTSELLER</h2>
        <h3 className="text-[1rem] font-semibold my-2  text-my-green">
          Bardzo intratna propozycja
        </h3>
        <p className="w-9/12 text-sm text-white">
          {" "}
          Wyższe założenie ideowe, a szczególnie zmiana istniejących kryteriów
          pomaga w większym
        </p>
        <p className="w-9/12 text-sm text-white font-thin">
          {" "}
          Wyższe założenie ideowe, a szczególnie zmiana istniejących kryteriów
          pomaga w większym Wyższe założenie ideowe, a szczególnie zmiana
          istniejących kryteriów pomaga w większym pomaga w większym Wyższe
          założenie ideowe, a szczególnie zmiana istniejących kryteriów pomaga w
          większym
        </p>
      </motion.div>
    </motion.div>
  );
}
