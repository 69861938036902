import { useState } from "react";
import { useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { motion } from "framer-motion";

export default function OrderForm() {
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
  } = useForm();
  const [orderId] = useState(
    Date.now().toString(36) + Math.random().toString(36).substr(2)
  );
  const totalAmount = useSelector((state) => state.cartSlice.totalAmount);
  return (
    <motion.div
      initial={{
        x: "100px",
        opacity: 0,
        transition: { duration: 0.6, ease: "easeInOut" },
      }}
      animate={{
        x: 0,
        opacity: 1,
        transition: { duration: 0.6, ease: "easeInOut" },
      }}
      exit={{
        x: "100px",
        opacity: 0,
        transition: { duration: 0.3, ease: "easeInOut" },
      }}
      className="flex justify-center items-start lg:px-10 px-4 py-5 bg-white rounded-t-3xl"
    >
      <form
        action="https://sandbox.paywall.imoje.pl/pl/payment"
        method="POST"
        acceptCharset="UTF-8"
        onSubmit={handleSubmit(async (data, e) => {
          e.preventDefault();
          console.log(data);
          //WIĘKSZE OD ZERA
          // const sygInput = document.createElement("input");
          // sygInput.setAttribute("type", "hidden");
          // sygInput.setAttribute("name", "signature");
          // e.target.appendChild(sygInput);

          // await signature(data)
          //   .then((res) => (sygInput.value = res.data))
          //   .then(() => makeOrder({ data, cartItems }))
          //   .then(() => dispatch(clear()));
          // .then(() => e.target.submit());
          // .catch(()=>);
        })}
      >
        <div className="text-my-dark-green text-sm  mb-3 font-bold uppercase">
          Dane Kontaktowe
        </div>
        <div className="flex flex-wrap">
          <div className="w-full lg:w-6/12 px-4">
            <div className="relative w-full">
              <label
                className="block uppercase text-my-dark-green02 text-xs font-bold mb-2"
                htmlFor="customerFirstName"
              >
                Imię:
              </label>
              <input
                {...register("customerFirstName", {
                  required: "Proszę wpisać Imię",
                  minLength: {
                    value: 3,
                    message: "Minimum trzy znaki",
                  },
                })}
                placeholder="Proszę wpisać imię"
                type="text"
                className="ring-1 ring-gray-300 border-0 px-3 py-3 placeholder-my-dark-green02 text-my-dark-green02 bg-gray-100 rounded text-sm  focus:outline-none focus:ring-my-green w-full ease-linear transition-all duration-150 focus:bg-white"
              />
              <p
                className={`${
                  errors.customerFirstName && "bg-red-500 text-white"
                } text-xs italic h-6 p-1 mt-1`}
              >
                {errors.customerFirstName?.message}
              </p>
            </div>
          </div>
          <div className="w-full lg:w-6/12 px-4">
            <div className="relative w-full ">
              <label
                className="block uppercase text-my-dark-green02 text-xs font-bold mb-2"
                htmlFor="customerLastName"
              >
                Nazwisko:
              </label>
              <input
                {...register("customerLastName", {
                  required: "Proszę wpisać nazwisko",
                  minLength: {
                    value: 3,
                    message: "Minimum trzy znaki",
                  },
                })}
                placeholder="Proszę wpisać nazwisko"
                type="text"
                className="ring-1 ring-gray-300 border-0 px-3 py-3 placeholder-my-dark-green02 text-my-dark-green02 bg-gray-100 rounded text-sm  focus:outline-none focus:ring-my-green w-full ease-linear transition-all duration-150 focus:bg-white"
              />
              <p
                className={`${
                  errors.customerLastName && "bg-red-500 text-white"
                } text-xs italic h-6 p-1 mt-1`}
              >
                {errors.customerLastName?.message}
              </p>
            </div>
          </div>
          <div className="w-full lg:w-6/12 px-4">
            <div className="relative w-full">
              <label
                className="block uppercase text-my-dark-green02 text-xs font-bold mb-2"
                htmlFor="customerEmail"
              >
                Email:
              </label>
              <input
                {...register("customerEmail", {
                  required: true,
                  pattern: {
                    value:
                      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                    message: "Proszę wpisać poprawny adres e-mail",
                  },
                })}
                placeholder="Proszę wpisać adres e-mail"
                type="email"
                className="ring-1 ring-gray-300 border-0 px-3 py-3 placeholder-my-dark-green02 text-my-dark-green02 bg-gray-100 rounded text-sm  focus:outline-none focus:ring-my-green w-full ease-linear transition-all duration-150 focus:bg-white"
              />
              <p
                className={`${
                  errors.customerEmail && "bg-red-500 text-white"
                } text-xs italic h-6 p-1 mt-1`}
              >
                {errors.customerEmail?.message}
              </p>
            </div>
          </div>
          <div className="w-full lg:w-6/12 px-4">
            <div className="relative w-full">
              <label
                className="block uppercase text-my-dark-green02 text-xs font-bold mb-2"
                htmlFor="customerEmail1"
              >
                Powtórz e-mail:
              </label>
              <input
                {...register("customerEmail1", {
                  required: true,
                  pattern: {
                    value:
                      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                    message: "Proszę wpisać poprawny adres e-mail",
                  },
                  validate: {
                    emailEqual: (value) =>
                      value === getValues().customerEmail ||
                      "Adresy różnią się",
                  },
                })}
                placeholder="Powtórz e-mail"
                type="text"
                className="ring-1 ring-gray-300 border-0 px-3 py-3 placeholder-my-dark-green02 text-my-dark-green02 bg-gray-100 rounded text-sm  focus:outline-none focus:ring-my-green w-full ease-linear transition-all duration-150 focus:bg-white"
              />
              <p
                className={`${
                  errors.customerEmail1 && "bg-red-500 text-white"
                } text-xs italic h-6 p-1 mt-1`}
              >
                {errors.customerEmail1?.message}
              </p>
            </div>
          </div>
          <div className="w-full lg:w-6/12 px-4">
            <div className="relative w-full">
              <label
                className="block uppercase text-my-dark-green02 text-xs font-bold mb-2"
                htmlFor="customerPhone"
              >
                Podaj nr. telefonu:
              </label>
              <input
                {...register("customerPhone", {
                  required: "Proszę wpisać numer telefonu",
                  pattern: {
                    value: /^[0-9+-]+$/,
                    message: "Proszę wpisać poprawny numer telefonu",
                  },
                  minLength: {
                    value: 6,
                    message: "Proszę wpisać poprawny numer telefonu",
                  },
                })}
                placeholder="Nr. telefonu"
                type="text"
                className="ring-1 ring-gray-300 border-0 px-3 py-3 placeholder-my-dark-green02 text-my-dark-green02 bg-gray-100 rounded text-sm  focus:outline-none focus:ring-my-green w-full ease-linear transition-all duration-150 focus:bg-white"
              />
              <p
                className={`${
                  errors.customerPhone && "bg-red-500 text-white"
                } text-xs italic h-6 p-1 mt-1`}
              >
                {errors.customerPhone?.message}
              </p>
            </div>
          </div>
          <div className="w-full lg:w-6/12 px-4">
            <div className="relative w-full">
              <label
                className="block uppercase text-my-dark-green02 text-xs font-bold mb-2"
                htmlFor="customerPhone1"
              >
                Powtórz nr. telefonu:
              </label>
              <input
                {...register("customerPhone1", {
                  required: "Proszę wpisać numer telefonu",
                  validate: {
                    emailEqual: (value) =>
                      value === getValues().customerPhone ||
                      "Numery różnią się!",
                  },
                })}
                placeholder="Nr. telefonu"
                type="text"
                className="ring-1 ring-gray-300 border-0 px-3 py-3 placeholder-my-dark-green02 text-my-dark-green02 bg-gray-100 rounded text-sm  focus:outline-none focus:ring-my-green w-full ease-linear transition-all duration-150 focus:bg-white"
              />
              <p
                className={`${
                  errors.customerPhone1 && "bg-red-500 text-white"
                } text-xs italic h-6 p-1 mt-1`}
              >
                {errors.customerPhone1?.message}
              </p>
            </div>
          </div>
        </div>

        <hr className="border-b-1 border-blueGray-300" />

        <h6 className="text-my-dark-green text-sm mt-3 mb-3 font-bold uppercase">
          Dane Adresowe
        </h6>
        <div className="flex flex-wrap">
          <div className="w-full lg:w-12/12 px-4">
            <div className="relative w-full">
              <label
                className="block uppercase text-my-dark-green02 text-xs font-bold mb-2"
                htmlFor="customerSrteet"
              >
                Adres (Ulica nr. domu)
              </label>
              <input
                {...register("customerSrteet", {
                  required: "Proszę wpisać adres",
                })}
                placeholder="Ulica nr. domu / nr. mieszkania"
                type="text"
                className="ring-1 ring-gray-300 border-0 px-3 py-3 placeholder-my-dark-green02 text-my-dark-green02 bg-gray-100 rounded text-sm  focus:outline-none focus:ring-my-green w-full ease-linear transition-all duration-150 focus:bg-white"
              />
              <p
                className={`${
                  errors.customerSrteet && "bg-red-500 text-white"
                } text-xs italic h-6 p-1 mt-1`}
              >
                {errors.customerSrteet?.message}
              </p>
            </div>
          </div>
          <div className="w-full lg:w-4/12 px-4">
            <div className="relative w-full ">
              <label
                className="block uppercase text-my-dark-green02 text-xs font-bold mb-2"
                htmlFor="customerCity"
              >
                Miasto
              </label>
              <input
                {...register("customerCity", {
                  required: "Proszę wpisać miasto",
                })}
                placeholder="Miasto"
                type="text"
                className="ring-1 ring-gray-300 border-0 px-3 py-3 placeholder-my-dark-green02 text-my-dark-green02 bg-gray-100 rounded text-sm  focus:outline-none focus:ring-my-green w-full ease-linear transition-all duration-150 focus:bg-white"
              />
              <p
                className={`${
                  errors.customerCity && "bg-red-500 text-white"
                } text-xs italic h-6 p-1 mt-1`}
              >
                {errors.customerCity?.message}
              </p>
            </div>
          </div>
          <div className="w-full lg:w-4/12 px-4">
            <div className="relative w-full">
              <label
                className="block uppercase text-my-dark-green02 text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
                Województwo
              </label>
              <input
                type="text"
                className="ring-1 ring-gray-300 border-0 px-3 py-3 placeholder-my-dark-green02 text-my-dark-green02 bg-gray-100 rounded text-sm  focus:outline-none focus:ring-my-green w-full ease-linear transition-all duration-150 focus:bg-white"
              />
            </div>
          </div>
          <div className="w-full lg:w-4/12 px-4">
            <div className="relative w-full">
              <label
                className="block uppercase text-my-dark-green02 text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
                Kod Pocztowy:
              </label>
              <input
                {...register("customerPostCode", {
                  required: "Proszę wpisdać kod pocztowy",
                })}
                placeholder="Kod Pocztowy"
                type="text"
                className="ring-1 ring-gray-300 border-0 px-3 py-3 placeholder-my-dark-green02 text-my-dark-green02 bg-gray-100 rounded text-sm  focus:outline-none focus:ring-my-green w-full ease-linear transition-all duration-150 focus:bg-white"
              />
              <p
                className={`${
                  errors.customerPostCode && "bg-red-500 text-white"
                } text-xs italic h-6 p-1 mt-1`}
              >
                {errors.customerPostCode?.message}
              </p>
            </div>
          </div>
        </div>

        <hr className="border-b-1 border-blueGray-300" />

        <div className="flex flex-wrap">
          <div className="w-full lg:w-12/12 px-4">
            <div className="relative w-full">
              <label
                className="block uppercase text-my-dark-green02 text-xs font-bold my-2"
                htmlFor="grid-password"
              >
                Twoja wiadomośc do sprzedawcy (opcionalnie)
              </label>
              <textarea
                type="text"
                className="ring-1 ring-gray-300 border-0 px-3 py-3 placeholder-my-dark-green02 text-my-dark-green02 bg-gray-100 rounded text-sm  focus:outline-none focus:ring-my-green w-full ease-linear transition-all duration-150 focus:bg-white"
                rows="2"
                placeholder="Twoa wiadomość do sprzedawcy"
              ></textarea>
            </div>
          </div>
        </div>
        <motion.button
          className="w-full bg-my-green py-2 text-white uppercase font-bold my-3"
          type="submit"
          whileHover={{
            scale: 1.05,
            transition: { duration: 0.5, type: "spring" },
          }}
          whileTap={{ scale: 0.9, transition: { type: "spring" } }}
        >
          Płacę ( {totalAmount + 15} PLN )
        </motion.button>
      </form>
    </motion.div>
  );
}
