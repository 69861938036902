import Promo from "./Promo";
import Slider from "./Slider";
export default function Header() {
  return (
    <header className="relative w-full h-screen">
      {/* <div className="absolute top-0 left-0 bg-[url('./images/header_bg.png')] md:bg-[length:80%] bg-[length:133%] bg-no-repeat md:bg-[center_top]  bg-[center_top_17rem] w-full h-full" /> */}
      <Slider />
      <Promo />
    </header>
  );
}
