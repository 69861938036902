import { createSelector, createEntityAdapter } from "@reduxjs/toolkit";
import { apiSlice } from "./apiSlice";

const sliderAdapter = createEntityAdapter({
  selectId: (slider) => slider.id,
});

const initialState = sliderAdapter.getInitialState();

export const extendedApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAllSliders: builder.query({
      query: () => "/sliders",
      transformResponse: (responseData) => {
        const loadedSliders = responseData.map((slider) => slider);
        return sliderAdapter.setAll(initialState, loadedSliders);
      },
      providesTags: (result, error, arg) =>
        result.length > 0
          ? [...result.map(({ id }) => ({ type: "Slider", id })), "Slider"]
          : ["Slider"],
    }),
  }),
});

export const { useGetAllSlidersQuery } = extendedApiSlice;

export const selectSliderResult =
  extendedApiSlice.endpoints.getAllSliders.select();

const selectSliderData = createSelector(
  selectSliderResult,
  (sliderResult) => sliderResult.data
);

export const {
  selectAll: selectAllSliders,
  selectById: selectSlidersById,
  selectIds: selectSlidersIds,
} = sliderAdapter.getSelectors(
  (state) => selectSliderData(state) ?? initialState
);
