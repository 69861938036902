import Filters from "./Filters/";
import ProductList from "./ProductList/";
import { motion } from "framer-motion";
export default function Shop() {
  return (
    <motion.section className="flex snap-start md:flex-row flex-col p-8 pt-[100px]">
      <Filters />
      <ProductList />
    </motion.section>
  );
}
// flex md:flex-row flex-col w-full min-h-screen justify-center items-start p-4 pt-[50px]
