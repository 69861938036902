import { motion, AnimatePresence } from "framer-motion";
import { useSelector, useDispatch } from "react-redux";
import { removeNotification } from "../../features/notificationsSlice";
import Notification from "./Notification";
export default function Notifications() {
  const notifications = useSelector(
    (state) => state.notificationsSlice.notifications
  );

  const color = (variant) => {
    switch (variant) {
      case "succes":
        return "bg-my-dark-green02";
        break;
      case "warring":
        return "bg-red-900";
        break;
      case "info":
        return "bg-my-dark-green";
        break;
      default:
        return "bg-my-green";
        break;
    }
  };

  const dispatch = useDispatch();

  if (notifications.length < 1) return;

  return (
    <div className="absolute bottom-0 left-0 w-1/3 h-auto  flex flex-col z-50 text-white p-5">
      <AnimatePresence>
        {notifications.map((notification) => (
          <Notification key={notification.id} notification={notification} />
        ))}
      </AnimatePresence>
    </div>
  );
}
