import { useState } from "react";
import { usePrevious } from "../../../hooks/usePrevious";
import { motion, Reorder, AnimatePresence } from "framer-motion";

export default function Quantity({ classes = "", quantity, setQuantity }) {
  //   const [quantity, setQuantity] = useState(1);
  const [prvQuantity, setPrvQuantity] = useState([null, quantity]);

  const variants = {
    enter: (direction) => ({
      y: direction * -30,
      opacity: 0,
      transition: { duration: 0.3, ease: "easeInOut" },
    }),
    center: {
      y: 0,
      opacity: 1,
      transition: { duration: 0.3, ease: "easeInOut" },
    },
    exit: (direction) => ({
      y: direction * 30,
      opacity: 0,
      transition: { duration: 0.3, ease: "easeInOut" },
    }),
  };
  let prev = usePrevious(quantity);
  let direction = quantity > prev ? 1 : -1;
  return (
    <div
      className={`relative flex flex-row flex-nowrap justify-center items-center border  text-center 2xl:py-1 px-1  font-bold text-sm ${classes}`}
    >
      <div className="relative 2xl:w-1/2 md:w-1/2 w-[40%] h-full flex justify-center items-center text-2xl overflow-hidden">
        <AnimatePresence custom={direction} initial={false}>
          <motion.div
            className="absolute top-0 left-0 w-full h-full flex justify-center items-center"
            key={quantity}
            variants={variants}
            custom={direction}
            initial="enter"
            animate="center"
            exit="exit"
          >
            {quantity}
          </motion.div>
        </AnimatePresence>
      </div>

      <div className=" flex 2xl:flex-row flex-col justify-center items-center ">
        <div
          className="cursor-pointer w-1/2"
          onClick={() => setQuantity(quantity + 1)}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="2xl:h-6 2xl:w-6 md:h-5 md:w-5 h-5 w-5"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            strokeWidth="2"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M5 15l7-7 7 7"
            />
          </svg>
        </div>
        <div
          className="cursor-pointer w-1/2"
          onClick={() => quantity > 1 && setQuantity(quantity - 1)}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="2xl:h-6 2xl:w-6 md:h-5 md:w-5 h-5 w-5"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            strokeWidth="2"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M19 9l-7 7-7-7"
            />
          </svg>
        </div>
      </div>
    </div>
  );
}
