const Footer = () => {
  return (
    <footer className="bg-footer-pattern h-[600px] relative text-white px-6 lg:px-8 py-60 overflow-hidden">
      <div className="max-w-screen-xl mx-auto mb-12 lg:mb-5  ">
        <img className="h-24" src="./images/logo_footer.png" alt="Phmed logo" />
      </div>
    </footer>
  );
};
export default Footer;
