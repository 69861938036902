/* eslint-disable jsx-a11y/img-redundant-alt */
import { useState } from "react";
import { useDispatch } from "react-redux";
import { addProduct } from "../../../features/cartSlice";
import Quantity from "./Quantity";
import { usePrevious } from "../../../hooks/usePrevious";
import { Link } from "react-router-dom";

import { motion, Reorder, AnimatePresence } from "framer-motion";

export default function Product({ product, delay }) {
  const { id, title, image, description, price, category } = product;
  console.log("image", image);
  const [quantity, setQuantity] = useState(1);

  let prev = usePrevious(quantity);
  let direction = quantity > prev ? 1 : -1;

  const variants = {
    enter: (direction) => ({
      y: direction * -30,
      opacity: 0,
      transition: { duration: 0.3, ease: "easeInOut" },
    }),
    center: {
      y: 0,
      opacity: 1,
      transition: { duration: 0.3, ease: "easeInOut" },
    },
    exit: (direction) => ({
      y: direction * 30,
      opacity: 0,
      transition: { duration: 0.3, ease: "easeInOut" },
    }),
  };
  const dispatch = useDispatch();

  return (
    <Reorder.Item
      initial={{
        scale: 0,
        opacity: 0,
        transition: { duration: 0.4, ease: "easeInOut" },
      }}
      animate={{
        scale: 1,
        opacity: 1,
        transition: { duration: 0.4, ease: "easeInOut", delay: delay },
      }}
      exit={{
        scale: 0,
        opacity: 0,
        transition: { duration: 0.3, ease: "easeInOut" },
      }}
      drag={false}
      className="group md:w-[23%] w-[48%] mx-[1%] md:mb-[2%] mb-[4%] 2xl:h-[350px] md:h-[270px] h-[250px] text-white overflow-hidden relative inline-flex bg-my-green shadow-sm"
    >
      <div className="font-montserrat bg-white flex flex-col justify-between h-full w-full group-hover:translate-x-[150%] duration-300">
        <div className="w-full h-2/6 p-2 z-10 ">
          <div className=" w-[100px] bg-my-green text-white absolute top-0 right-0 rotate-45 px-10 translate-x-[25%] translate-y-[50%] text-sm text-center font-bold">
            eko
          </div>
          <div className=" py-[2%] 2xl:text-[0.85rem] text-[0.75rem] text-my-green font-semibold ">
            POLISHEMP
          </div>
          <div className="2xl:font-light  2xl:text-[1.3rem] font-light md:text-[1.125rem] text-[1rem] text-my-dark-green02 uppercase">
            <h3>{title}</h3>
          </div>
          <div className="py-[2%] text-my-dark-green 2xl:text-[0.85rem] text-[0.7rem] font-semibold uppercase">
            {category}
          </div>
        </div>
        <div className="bg-black md:w-20 w-16 text-2xl py-1 text-center m-2 self-end font-bold z-10">
          <strong> {price} PLN</strong>
        </div>
        <div className="absolute w-full h-2/3 flex justify-center items-start overflow-hidden left-0 bottom-0 z-0">
          <img src={`/images/${image[0]}`} alt="Product" className="h-full" />
        </div>
      </div>
      <div className="text-white flex flex-col justify-between h-full w-full absolute top-0 p-2 -left-[100%]  group-hover:translate-x-[100%] duration-300">
        <div>
          <h3 className="font-montserrat 2xl:text-[1.75rem] text-[1rem] font-extrabold  mb-2  uppercase">
            {title}
          </h3>
          <p className="long 2xl:font-light md:font-normal 2xl:text-sm text-xs leading-[1.35] text-justify">
            {description.split(" ").slice(0, 32).join(" ")} ...
          </p>
        </div>
        <div className="flex flex-wrap flex-row justify-between">
          <Link to={`./produkt/${id}`} className="w-full mb-[2%]">
            <button className="w-full bg-white text-my-green hover:text-white hover:bg-my-green text-[0.8rem] font-semibold 2xl:py-[5%] py-[6%] border border-white transform duration-300 ease-in-out">
              Zobacz więcej
            </button>
          </Link>

          <Quantity
            quantity={quantity}
            setQuantity={setQuantity}
            classes={"2xl:w-2/5 md:w-1/3 w-1/3  border-white text-white"}
          />
          <div className=" 2xl:w-1/6 md:w-1/4 w-1/4 flex flex-col justify-between items-center text-center border border-white  text-white font-bold overflow-hidden">
            <div className="relative w-full h-1/2 flex justify-center items-center">
              <AnimatePresence custom={direction}>
                <motion.div
                  key={id + "_" + quantity}
                  variants={variants}
                  custom={direction}
                  initial="enter"
                  animate="center"
                  exit="exit"
                  className="absolute top-0 left-0 w-full  flex justify-center items-center overflow-hidden "
                >
                  {price * quantity}
                </motion.div>
              </AnimatePresence>
            </div>
            <div className="w-full h-1/2 flex justify-center items-center">
              PLN
            </div>
          </div>
          <button
            className="2xl:w-2/5 md:w-1/3 w-1/3 2xl:p-4 bg-my-yellow hover:bg-white text-my-dark-green hover:text-my-green font-bold text-[0.8rem] transform duration-300 ease-in-out"
            onClick={() => dispatch(addProduct({ ...product, quantity }))}
          >
            Kupuję
          </button>
        </div>
      </div>
    </Reorder.Item>
  );
}
