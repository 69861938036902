import { createSlice } from "@reduxjs/toolkit";
import { addProduct, removeProduct } from "./cartSlice";

function isRejectedAction(action) {
  return action.type.endsWith("rejected");
}

export const notificationsSlice = createSlice({
  name: "Notifications",
  initialState: {
    notifications: [],
    toggleHide: false,
  },
  reducers: {
    addNotification: (state, action) => {
      state.notifications = [...state.notifications, action.payload];
    },
    removeNotification: (state, action) => {
      state.notifications = [...state.notifications].filter(
        (notification) => notification.id !== action.payload
      );
    },
    toggleHide: (state, action) => {
      state.toggleHide = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(addProduct, (state, action) => {
        const newNotification = {
          id: state.notifications.length + 2,
          status: "succes",
          message: `Dodano do Koszyka Produkt ${action.payload.title}`,
        };
        state.notifications = [...state.notifications, newNotification];
      })
      .addCase(removeProduct, (state, action) => {
        const newNotification = {
          id: state.notifications.length + 2,
          status: "warring",
          message: `Usunięto produkt z koszyka `,
        };
        state.notifications = [...state.notifications, newNotification];
      });
  },
});
export const { toggleHide, addNotification, removeNotification } =
  notificationsSlice.actions;
export default notificationsSlice.reducer;
