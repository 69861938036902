export default function Pagination({
  postsPerPage,
  totalPosts,
  currentPage,
  paginate,
}) {
  const pageNumbers = [];
  const lastPage = Math.ceil(totalPosts / postsPerPage);
  for (let i = 1; i <= Math.ceil(totalPosts / postsPerPage); i++) {
    pageNumbers.push(i);
  }
  return (
    <div className="flex flex-col items-center  self-end">
      <div className="flex text-gray-700">
        {currentPage !== 1 && (
          <div
            className="h-8 w-8 mr-1 flex justify-center items-center rounded-full bg-gray-200 cursor-pointer"
            onClick={() => paginate(currentPage - 1)}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="100%"
              height="100%"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="feather feather-chevron-left w-4 h-4"
            >
              <polyline points="15 18 9 12 15 6"></polyline>
            </svg>
          </div>
        )}
        {currentPage > 2 && (
          <>
            <div className="w-8 md:flex justify-center items-center hidden  cursor-pointer leading-5 transition duration-150 ease-in  rounded-full  ">
              1
            </div>
            <div className="w-8 md:flex justify-center items-center hidden  cursor-pointer leading-5 transition duration-150 ease-in  rounded-full  ">
              ...
            </div>
          </>
        )}
        {currentPage > 1 && (
          <div className="w-8 md:flex justify-center items-center hidden  cursor-pointer leading-5 transition duration-150 ease-in  rounded-full  ">
            {currentPage - 1}
          </div>
        )}
        <div className="w-8 md:flex justify-center items-center hidden  cursor-pointer leading-5 transition duration-150 ease-in  bg-my-green text-white ">
          {currentPage}
        </div>
        {currentPage + 1 <= lastPage && (
          <div className="w-8 md:flex justify-center items-center hidden  cursor-pointer leading-5 transition duration-150 ease-in  rounded-full  ">
            {currentPage + 1}
          </div>
        )}
        {currentPage + 2 <= lastPage && (
          <>
            <div className="w-8 md:flex justify-center items-center hidden  cursor-pointer leading-5 transition duration-150 ease-in  rounded-full  ">
              ...
            </div>
            <div className="w-8 md:flex justify-center items-center hidden  cursor-pointer leading-5 transition duration-150 ease-in  rounded-full  ">
              {lastPage}
            </div>
          </>
        )}

        <div className="flex h-8 font-medium rounded-full bg-gray-200">
          {totalPosts > postsPerPage && (
            <div className="w-8 h-8 md:hidden flex justify-center items-center cursor-pointer leading-5 transition duration-150 ease-in rounded-full bg-my-green text-white">
              {currentPage}
            </div>
          )}
        </div>
        {currentPage < lastPage && totalPosts > postsPerPage && (
          <div
            className="h-8 w-8 ml-1 flex justify-center items-center rounded-full bg-gray-200 cursor-pointer"
            onClick={() => paginate(currentPage + 1)}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="100%"
              height="100%"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="feather feather-chevron-right w-4 h-4"
            >
              <polyline points="9 18 15 12 9 6"></polyline>
            </svg>
          </div>
        )}
      </div>
    </div>
  );
}
