import { createSelector, createEntityAdapter } from "@reduxjs/toolkit";
import { apiSlice } from "./apiSlice";
import { setCategoryFilter, setPriceValuesFilter } from "./filtersSlice";
const productAdapter = createEntityAdapter({
  selectId: (el) => el.id,
  sortComparter: (a, b) => b.timestamps.locateCompare(a.timestamps),
});

const initialState = productAdapter.getInitialState();

export const extendedApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAllProducts: builder.query({
      query: () => "/products",
      transformResponse: (responseData) => {
        const loadedProducts = responseData.map((product) => {
          product.quantity = 1;
          return product;
        });
        return productAdapter.setAll(initialState, loadedProducts);
      },
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        const { data } = await queryFulfilled;
        const hightPrice =
          Math.max(...Object.values(data.entities)?.map((o) => o.price), 0) + 1;
        const lowPrice =
          Object.values(data.entities).length > 0
            ? Math.min(...Object.values(data.entities)?.map((o) => o.price)) - 1
            : 0;
        dispatch(
          setCategoryFilter([
            ...new Set(Object.values(data.entities).map((el) => el.category)),
          ])
        );
        dispatch(setPriceValuesFilter([lowPrice, hightPrice]));
      },
      providesTags: (result, error, arg) => [
        { type: "Product", id: "LIST" },
        ...result.ids.map((id) => ({ type: "Product", id })),
      ],
    }),
  }),
});

export const { useGetAllProductsQuery } = extendedApiSlice;

export const selectProductResult =
  extendedApiSlice.endpoints.getAllProducts.select();

const selectProductData = createSelector(
  selectProductResult,
  (productResult) => productResult.data
);

export const { selectAll: selectAllProducts, selectById: selectProductsById } =
  productAdapter.getSelectors(
    (state) => selectProductData(state) ?? initialState
  );
