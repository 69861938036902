import { useEffect, useRef } from "react";

export default function Kontakt() {
  const scollToRef = useRef();
  useEffect(() => {
    scollToRef.current.scrollIntoView({
      behavior: "smooth",
      inline: "nearest",
    });
  }, []);

  return (
    <section ref={scollToRef} className="w-full h-screen p-4 pt-[100px]">
      Kontakt
    </section>
  );
}
