import { createSlice } from "@reduxjs/toolkit";

export const cartSlice = createSlice({
  name: "Cart",
  initialState: {
    items: [],
    totalAmount: 0,
    totalQuantity: 0,
  },
  reducers: {
    addProduct: (state, action) => {
      let is = false;

      state.items.forEach((item) => {
        if (item.id === action.payload.id) {
          item.quantity += action.payload.quantity;
          // state.totalQuantity += action.payload.quantity;
          is = true;
        }
      });

      !is && state.items.push(action.payload);
      !is && state.totalQuantity++;

      state.totalAmount += action.payload.price * action.payload.quantity;
    },
    removeProduct: (state, action) => {
      state.items = state.items.filter((item) => item.id !== action.payload);
      state.totalAmount = state.items
        .map((el) => el.price * el.quantity)
        .reduce((prv, curr) => prv + curr);
      state.totalQuantity--;
    },
    increase: (state, action) => {
      state.items[action.payload].quantity++;
      state.totalAmount += state.items[action.payload].price;
    },
    decrease: (state, action) => {
      state.items[action.payload].quantity--;
      state.totalAmount -= state.items[action.payload].price;
    },
    setQuantity: (state, action) => {
      // let is = false;
      let totalAmount;
      totalAmount = 0;
      state.items.forEach((item) => {
        if (item.id === action.payload.item.id) {
          item.quantity = action.payload.qty;
          totalAmount += action.payload.item.price * action.payload.qty;
          // state.totalQuantity += action.payload.quantity;
          // is = true;
        } else {
          totalAmount += item.price * item.quantity;
        }
        state.totalAmount = totalAmount;
      });

      // !is && state.items.push(action.payload);
      // !is && state.totalQuantity++;
    },
    clear: (state) => {
      state.items = [];
      state.totalAmount = 0;
      state.totalQuantity = 0;
    },
  },
});
export const { addProduct, removeProduct, setQuantity, clear } =
  cartSlice.actions;
export default cartSlice.reducer;
