import { motion } from "framer-motion";
export default function Onas() {
  return (
    <motion.section
      initial={{
        y: -100,
        opacity: 0,
        transition: { duration: 0.6, ease: "easeInOut" },
      }}
      animate={{
        y: 0,
        opacity: 1,
        transition: { duration: 0.6, ease: "easeInOut" },
      }}
      exit={{
        y: 100,
        opacity: 0,
        transition: { duration: 0.3, ease: "easeInOut" },
      }}
      className="w-full flex flex-col items-center text-justify text-sm py-8 px-4 pt-[100px]  leading-7"
    >
      <div className="font-bebas text-my-dark-green02 text-8xl">O NAS</div>
      <p className="md:w-1/2 mt-3">
        <span className="block font-bold mb-3">
          ullam tincidunt, eros eget egestas elementum, quam augue vulputate
          enim, vel eleifend velit dui quis dui. Phasellus eu sapien arcu.
          Aliquam erat volutpat.
        </span>
        Nam maximus varius elit. Mauris tincidunt porttitor leo non egestas.
        Duis sollicitudin libero vitae justo interdum placerat. In enim augue,
        aliquet eget quam at, convallis pharetra enim. Morbi commodo, metus eu
        lacinia vestibulum, nunc elit ultrices velit, in vulputate magna ipsum
        at lacus. Vivamus non lacus consequat purus efficitur malesuada. Nullam
        vestibulum, augue ac pellentesque cursus, dolor justo fringilla nisl,
        sit amet bibendum est sem at nulla. Phasellus ac dictum mi. Nunc tempor
        elit sem, quis placerat elit pellentesque vel. Quisque luctus, eros
        vitae vulputate posuere, lacus purus vehicula orci, vel finibus erat
        elit vel velit. Curabitur vel tempus orci. In fermentum dignissim
        libero, sed pulvinar leo rutrum ut. Suspendisse rhoncus porta ante, vel
        laoreet dolor. Proin scelerisque, purus vel luctus iaculis, felis nisl
        bibendum tellus, vitae pulvinar libero sapien eu nisi. Quisque lectus
        erat, laoreet a est eget, ultrices vehicula urna. Nulla sollicitudin
        nibh id convallis porttitor. Quisque porta ante enim, vitae bibendum
        erat tincidunt sit amet. Mauris eu orci tellus. Cras facilisis mi a
        tristique blandit. Fusce efficitur efficitur pharetra. Aenean vulputate
        porttitor sagittis. Proin mauris lacus, hendrerit ac ex at, dignissim
        elementum est. Nam pulvinar, nisl nec finibus tristique, eros tortor
        molestie risus, id interdum urna sem vitae purus. Maecenas fermentum
        nisi a massa maximus, a dignissim risus dapibus. Curabitur sed quam at
        tortor pharetra lacinia et eu lectus. In finibus massa eget nunc mollis,
        ac placerat turpis bibendum. Vivamus eu erat tellus. Aenean nec elit
        quis massa fermentum imperdiet.{" "}
      </p>
    </motion.section>
  );
}
