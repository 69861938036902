import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Menu from "./Menu";
import { motion } from "framer-motion";

export default function Navigation() {
  const totalQuantity = useSelector((state) => state.cartSlice.totalQuantity);

  const scroll = useSelector((state) => state.navigationScrollSlice.toggleHide);
  const [hover, setHover] = useState(false);

  return (
    <nav
      className={`${
        scroll
          ? scroll && hover
            ? "bg-white h-1/5 shadow-lg"
            : "bg-white h-[80px] shadow-lg"
          : "2xl:h-[200px] md:h-[150px] h-[100px] bg-transparent"
      }   w-full md:px-8 px-2 flex md:py-2 pt-1 justify-between fixed transform duration-300  ease-in-out z-50`}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <motion.div
        initial={{ y: -200, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ duration: 0.5, type: "spring" }}
        className="h-full"
      >
        <img
          src="./images/logo.svg"
          alt="Logo"
          className="h-full md:py-[2%] py-[4%] 2xl:mt-0 md:mt-4 mt-0 "
        />
      </motion.div>
      <motion.div
        initial={{ y: -200, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ duration: 0.5, delay: 0.1, type: "spring" }}
        className="flex justify-end items-center h-full"
      >
        <div className="relative flex items-center justify-center h-full pt-[2%] pb-[8%] mr-2">
          <img src="./images/torba.svg" alt="Koszyk" className="h-full" />
          <Link to={"koszyk"}>
            {totalQuantity > 0 ? (
              <div className="flex justify-center items-center font-bold text-white text-[90%] p-[10px] bg-orange-700 w-[35%] h-[20%] rounded-full absolute -left-2 bottom-2">
                <div className="animate-ping bg-orange-500 w-full h-full rounded-full items-center justify-center absolute"></div>
                {totalQuantity}
              </div>
            ) : null}
          </Link>
        </div>
        <div
          className={`${
            scroll
              ? scroll && hover
                ? "w-0 h-0"
                : " md:ml-4 ml-2 w-10 h-10 delay-500 "
              : "w-0 h-0"
          } flex flex-col justify-center items-center bg-my-green rounded-full transform duration-300 ease-in-out`}
        >
          <span className="block w-1/2 h-0.5 bg-white"></span>
          <span className="block w-1/2 h-0.5 mt-1.5 bg-white"></span>
          <span className="block w-1/2 h-0.5 mt-1.5 bg-white"></span>
        </div>
        <div
          className={`${
            scroll
              ? scroll && hover
                ? "w-7/12 h-full"
                : "w-0 h-0"
              : "w-7/12 h-full"
          }  transform duration-500  ease-in-out z-50`}
        >
          <Menu />
        </div>
      </motion.div>
    </nav>
  );
}
