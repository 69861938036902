import { useState, useEffect, useRef } from "react";
import { setPriceValuesFilter } from "../../../features/filtersSlice";
import { useDispatch } from "react-redux";

import { motion, useDragControls } from "framer-motion";

export default function RangeSlider() {
  const dispatch = useDispatch();

  const controls = useDragControls();

  const [min, max, step] = [20, 1500, 10];

  const progressRef = useRef(null);
  const progressTrackRef = useRef(null);
  const trackWidthInPx = progressTrackRef.current?.offsetWidth;

  const [minValue, setMinValue] = useState(0);
  const [maxValue, setMaxValue] = useState(100);

  useEffect(() => {
    minValue >= 0 && (progressRef.current.style.left = minValue + "%");
    maxValue <= 100 && (progressRef.current.style.right = 100 - maxValue + "%");
    dispatch(
      setPriceValuesFilter([
        Math.ceil(minValue) * (max / 100),
        Math.ceil(maxValue) * (max / 100),
      ])
    );
  }, [minValue, maxValue]);
  return (
    <div className="w-full flex justify-center items-center">
      <div className="flex flex-col relative  w-full">
        <div className="relative w-full mt-9">
          <div
            ref={progressTrackRef}
            className="absolute z-10  w-full h-2 left-0 right-0 bottom-0 top-0 rounded-md bg-gray-300"
          ></div>
          <div
            ref={progressRef}
            className="absolute  h-2 z-20 top-0 bottom-0 rounded-md md:bg-my-green bg-my-dark-green02"
          ></div>

          <motion.div
            initial={{ x: 0 }}
            transition={{ type: "spring", stiffness: 400, damping: 17 }}
            whileHover={{ scale: 1.2 }}
            whileTap={{ scale: 0.9 }}
            dragMomentum={false}
            drag="x"
            controls={controls}
            dragConstraints={{
              left: 0,
              right: maxValue * ((trackWidthInPx - 33) / 100),
            }}
            onDrag={(event, info) => {
              const newValue =
                info.point.x >= 33
                  ? (100 * (info.point.x - 33)) / trackWidthInPx < 100
                    ? (100 * (info.point.x - 33)) / trackWidthInPx < maxValue
                      ? (100 * (info.point.x - 33)) / trackWidthInPx
                      : maxValue
                    : 100
                  : 0;
              setMinValue(newValue);
            }}
            className="absolute z-30 w-6 h-6 top-0 left-0 md:bg-my-green bg-my-dark-green  rounded-full -mt-2 cursor-pointer"
          />

          <motion.div
            initial={{ x: 400 }}
            dragMomentum={false}
            drag="x"
            controls={controls}
            dragConstraints={{
              left: minValue * (trackWidthInPx / 100),
              right: trackWidthInPx - 16,
            }}
            onDrag={(event, info) => {
              setMaxValue(
                info.point.x < trackWidthInPx + 33
                  ? (100 * (info.point.x - 33)) / trackWidthInPx > minValue
                    ? (100 * (info.point.x - 33)) / trackWidthInPx
                    : minValue
                  : 100
              );
            }}
            whileHover={{ scale: 1.2 }}
            whileTap={{ scale: 0.9 }}
            transition={{ type: "spring", stiffness: 400, damping: 17 }}
            className="absolute z-30 w-6 h-6 top-0 left-0 md:bg-my-green bg-my-dark-green rounded-full -mt-2 cursor-pointer"
          />
        </div>

        <div className="flex justify-between items-center mt-7">
          <div>
            <input
              type="text"
              maxLength="5"
              className="px-3 py-2 border border-gray-200 rounded w-24 text-center"
              value={Math.ceil(minValue) * (max / 100)}
              readOnly
            />
          </div>
          <div>
            <input
              type="text"
              maxLength="5"
              className="px-3 py-2 border border-gray-200 rounded w-24 text-center"
              value={Math.ceil(maxValue) * (max / 100)}
              readOnly
            />
          </div>
        </div>
      </div>
    </div>
  );
}
