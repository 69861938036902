import { useEffect } from "react";
import { motion } from "framer-motion";
import { useDispatch } from "react-redux";
import { removeNotification } from "../../features/notificationsSlice";

export default function Notification({ notification }) {
  const color = (variant) => {
    switch (variant) {
      case "succes":
        return "bg-my-dark-green02";
        break;
      case "warring":
        return "bg-red-900";
        break;
      case "info":
        return "bg-my-dark-green";
        break;
      default:
        return "bg-my-green";
        break;
    }
  };

  useEffect(() => {
    const timer = setTimeout(
      () => dispatch(removeNotification(notification.id)),
      4000
    );
    return () => {
      clearInterval(timer);
    };
  }, []);

  const dispatch = useDispatch();
  return (
    <motion.div
      key={notification.id}
      layout
      initial={{ opacity: 0, y: 500 }}
      animate={{
        opacity: 1,
        y: 0,
        transition: { duration: 0.3 },
      }}
      exit={{
        opacity: 0,
        y: 50,
        transition: { duration: 0.6 },
      }}
      className={`flex flex-col p-4 my-1 ${color(
        notification.status
      )} drop-shadow-[0_0_10px_rgba(0,0,0,0.25)] rounded-3xl`}
    >
      <span className="w-full font-bold">{notification.message}</span>
      <p>Wyśmienity wybór 👌</p>
      <motion.div
        className="leading-1 absolute top-1 right-4 p-0 w-8 h-8 flex justify-center items-center  text-white text-4xl cursor-pointer origin-center"
        whileHover={{
          rotate: "90deg",
        }}
        whileTap={{
          scale: 0.9,
        }}
      >
        <div
          onClick={() => dispatch(removeNotification(notification.id))}
          className=" -translate-y-[10%]"
        >
          &times;
        </div>
      </motion.div>
    </motion.div>
  );
}
