import { useState, useEffect, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";

import {
  useGetAllProductsQuery,
  selectAllProducts,
} from "../../../features/productSlice";

import { motion, Reorder, AnimatePresence } from "framer-motion";

import { setPaginationPage } from "../../../features/filtersSlice";

import Product from "./Product";
import Pagination from "./Pagination";
import SEO from "../../SEO";
import Loader from "../../Loader";

export default function ProductList() {
  const { error, isLoading } = useGetAllProductsQuery();

  const productsData = useSelector(selectAllProducts);

  const [productsList, setProductsList] = useState([]);

  const paginationPage = useSelector(
    (state) => state.filtersSlice.paginationPage
  );

  const titleFilter = useSelector((state) => state.filtersSlice.title);
  const categoryFilter = useSelector((state) => state.filtersSlice.category);
  const priceValuesFilter = useSelector(
    (state) => state.filtersSlice.priceValues
  );
  const dispatch = useDispatch();

  useMemo(() => {
    setProductsList(
      productsData?.filter(
        (item) =>
          item.title.toLowerCase().includes(titleFilter.toLowerCase()) &&
          categoryFilter.indexOf(item.category) >= 0 &&
          item.price > priceValuesFilter[0] &&
          item.price < priceValuesFilter[1]
      )
    );
  }, [titleFilter, categoryFilter, priceValuesFilter]);

  useEffect(() => {
    dispatch(setPaginationPage(1));
  }, [titleFilter, categoryFilter, priceValuesFilter]);

  if (isLoading) return <Loader />;
  if (error) return <>Error!!!</>;
  console.log("product list:", productsData);
  return (
    <>
      <SEO
        title="Phmed - konopie sklep"
        description="Konopie produkty:"
        name="Phmed"
        schema={`{
            "@context": "https://schema.org",
            "@type": "ItemList",
            "url": "http://phmed.pl",
            "name": "PHMed - Produkty",
            "numberOfItems": ${productsData?.length},
            "itemListElement": [${productsData?.map(
              (item, i) =>
                `{
                "@type": "Product",
                "position": ${i + 1},
                "image": "https://phmed.pl/pictures/${item.image[0]}",
                "url": "https://phmed.pl/produkt/${item.id}",
                "name": "${item.title}",
                "description":"${item.description
                  .split(" ")
                  .slice(0, 32)
                  .join(" ")}",
                "offers": {
                  "@type": "Offer",
                  "priceCurrency":"PLN",
                  "url": "https://phmed.pl/produkt/${item.id}",
                  "price": ${item.price}
                }}`
            )}]
          }`}
      />
      <motion.div
        initial={{
          x: "100px",
          opacity: 0,
          transition: { duration: 0.6, ease: "easeInOut" },
        }}
        animate={{
          x: 0,
          opacity: 1,
          transition: { duration: 0.6, ease: "easeInOut" },
        }}
        exit={{
          x: "100px",
          opacity: 0,
          transition: { duration: 0.3, ease: "easeInOut" },
        }}
        className="relative flex flex-col justify-start items-start md:w-3/4 w-full min-h-screen pb-24"
      >
        <div className="flex flex-wrap w-full min-h-screen overflow-hidden">
          <Reorder.Group
            axis="x"
            values={productsData}
            as="div"
            className="w-full"
          >
            <AnimatePresence mode="wait" initial={false}>
              {productsData
                .slice((paginationPage - 1) * 8, (paginationPage - 1) * 8 + 8)
                ?.map((product, i) => (
                  <Product
                    key={product.id}
                    product={product}
                    delay={i * 0.075}
                  />
                ))}
            </AnimatePresence>
          </Reorder.Group>
        </div>
        <div
          className="absolute bottom-16
        right-4 w-full flex justify-end items-start  "
        >
          {productsData?.length > 8 && (
            <Pagination
              postsPerPage={8}
              totalPosts={productsData?.length}
              currentPage={paginationPage}
              paginate={(e) => dispatch(setPaginationPage(e))}
            />
          )}
        </div>
      </motion.div>
    </>
  );
}
