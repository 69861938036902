import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { setQuantity, removeProduct } from "../../../features/cartSlice";

import { motion, AnimatePresence } from "framer-motion";
import Quantity from "../ProductList/Quantity";
import { usePrevious } from "../../../hooks/usePrevious";
import { Link } from "react-router-dom";

export default function OrderedProductListItem({ item }) {
  let prev = usePrevious(item.quantity);
  let direction = item.quantity > prev ? 1 : -1;
  const variants = {
    enter: (direction) => ({
      y: direction * -20,
      opacity: 0,
      height: "auto",
      transition: {
        y: { duration: 0.3, ease: "easeInOut" },
        opacity: { duration: 0.5, ease: "easeInOut" },
      },
    }),
    center: {
      y: 0,
      opacity: 1,
      transition: {
        y: { duration: 0.3, ease: "easeInOut" },
        opacity: { duration: 0.5, ease: "easeInOut" },
      },
    },
    exit: (direction) => ({
      y: direction * 20,
      opacity: 0,
      transition: {
        y: { duration: 0.3, ease: "easeInOut" },
        opacity: { duration: 0.3, ease: "easeInOut" },
      },
    }),
  };
  const dispatch = useDispatch();
  return (
    <motion.li
      key={item.id}
      initial={{ opacity: 0, height: 0 }}
      animate={{ opacity: 1, height: "auto" }}
      exit={{ opacity: 0, height: 0 }}
      transition={{
        opacity: { duration: 0.2 },
        height: { duration: 0.3 },
      }}
      className="relative w-full py-0.5 flex justify-between items-center px-4"
    >
      <div className="md:w-1/6 w-1/12 flex justify-start items-start">
        <img
          src={`/images/${item.image[0]}`}
          className="w-full md:px-8 p-[4%]"
          alt="Product Thumbnail"
        />
      </div>
      <div className="w-2/6 text-left">
        <p className="mb-2">
          <Link to={`/produkt/${item.id}`}>{item.title}</Link>
        </p>

        <button
          className="text-gray-700"
          onClick={() => dispatch(removeProduct(item.id))}
        >
          <small className="text-sm">
            (Remove item){" "}
            <span className="w-5 h-8 text-xl px-2 text-orange-800 border border-orange-800 rounded-lg">
              &times;
            </span>
          </small>
        </button>
      </div>
      <div className="w-1/6 flex justify-end">
        <Quantity
          quantity={item.quantity}
          setQuantity={(qty) =>
            dispatch(
              setQuantity({
                qty,
                item: { id: item.id, price: item.price },
              })
            )
          }
          classes={
            "border-my-dark-green02 md:w-1/2 w-full text-my-dark-green02"
          }
        />
      </div>
      <div className="w-1/6 text-right">{item.price} PLN</div>
      <div className="w-1/6 h-full  flex justify-end">
        <span className=" flex md:flex-row flex-col w-full h-full md:justify-end justify-center md:items-start items-end  uppercase text-right font-bold">
          <div className="relative md:w-1/6 w-full md:h-full h-1/2">
            <AnimatePresence custom={direction} initial={false}>
              <motion.div
                key={item.id + "_" + item.quantity}
                variants={variants}
                custom={direction}
                initial="enter"
                animate="center"
                exit="exit"
                className="absolute top-0 right-0 min-w-full h-full text-lg md:mr-1 mr-0"
              >
                {item.quantity * item.price}
              </motion.div>
            </AnimatePresence>
          </div>
          <div className=" md:w-1/6 w-full md:h-full h-1/2 text-lg">PLN</div>
        </span>
      </div>
    </motion.li>
  );
}
