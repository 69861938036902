import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  setTitleFilter,
  setPriceValuesFilter,
  setCategoryFilter,
} from "../../../features/filtersSlice";
import { motion } from "framer-motion";
import RangeSlider from "./RangeSlider";
import { selectAllProducts } from "../../../features/productSlice";
import useScreenOrientation from "../../../hooks/useScreenOrientation";
export default function Filters() {
  const dispatch = useDispatch();

  const productsData = useSelector(selectAllProducts);

  const rangePriceValues = useSelector(
    (state) => state.filtersSlice.priceValues
  );

  const cateroryFilterValues = useSelector(
    (state) => state.filtersSlice.category
  );

  const categories = [
    ...new Set(Object.values(productsData).map((el) => el.category)),
  ];

  const hightPrice = Math.max(...productsData?.map((o) => o.price), 0) + 1;
  const lowPrice =
    productsData.length > 0
      ? Math.min(...productsData?.map((o) => o.price)) - 1
      : 0;
  const screenOrientation = useScreenOrientation();
  const toggleViewButton = true;
  // const [toggleViewButton] = useState(
  //   screenOrientation === "portrait-primary"
  //     ? true
  //     : screenOrientation === "portrait-secondary"
  //     ? true
  //     : false
  // );

  const [toggleViewFilters, setToggleViewFilters] = useState(false);
  return (
    <motion.div
      initial={{
        x: "-100px",
        opacity: 0,
        transition: { duration: 0.3, ease: "easeInOut" },
      }}
      animate={{
        x: 0,
        opacity: 1,
        transition: { duration: 0.3, ease: "easeInOut" },
      }}
      exit={{
        x: "-100px",
        opacity: 0,
        transition: { duration: 0.3, ease: "easeInOut" },
      }}
      id="filters"
      className="flex justify-start items-start flex-col md:w-1/4 w-full md:h-full md:border-r border-dotted border-[#404040]  md:pr-4"
    >
      {console.log(screenOrientation)}
      {toggleViewButton && (
        <motion.button
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.9 }}
          className="flex justify-center items-center w-full p-4 bg-my-dark-green border-0 text-white  uppercase text-xl font-montserrat font-light"
          onClick={() => setToggleViewFilters(!toggleViewFilters)}
        >
          <span className="mr-2">Filtry </span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 300.9 300.9"
            className="fill-my-green w-5"
          >
            <path d="M289 0H12A10 10 0 0 0 2 10v49.5a10 10 0 0 0 10 10h12.3L115.7 177v114a10 10 0 0 0 15.5 8.3l49.5-33a10 10 0 0 0 4.5-8.3v-81l91.4-107.4H289a10 10 0 0 0 10-10V10a10 10 0 0 0-10-10zM167.6 166.8a10 10 0 0 0-2.4 6.4v79.4l-29.5 19.6v-99a10 10 0 0 0-2.4-6.4L50.6 69.5h199.7l-82.7 97.3zM279 49.5H22V20h257v29.5z" />
          </svg>
        </motion.button>
      )}
      <motion.div
        animate={toggleViewFilters ? "open" : "closed"}
        variants={{
          open: {
            height: 0,
            transition: {
              type: "spring",
              bounce: 0,
              duration: 0.4,
            },
          },
          closed: {
            height: "auto",
            transition: {
              type: "spring",
              bounce: 0,
              duration: 0.3,
              when: "afterChildren",
            },
          },
        }}
        style={{ pointerEvents: toggleViewFilters ? "auto" : "none" }}
        className="w-full flex flex-col justify-start items-start mb-6 overflow-hidden m md:bg-transparent bg-my-green md:p-0 p-4"
      >
        <div className="w-full hidden bg-my-dark-green02 text-white text-2xl font-montserrat font-light p-4">
          FILTRY
        </div>
        <input
          type="nazwa"
          placeholder="NAZWA PRODUKTU"
          className="w-full my-5 border-b-2 p-2 bg-transparent md:border-my-green border-white  accent-my-dark-green  focus:outline-none outline-none focus:ring-0 focus:border-my-dark-green md:placeholder-my-dark-green placeholder-white"
          onChange={(e) => dispatch(setTitleFilter(e.target.value))}
        />
        <div className="w-full my-5 p-2 md:text-white text-my-green text-xl font-montserrat font-light md:bg-my-green bg-white uppercase">
          Cena:
        </div>
        <div className="w-full flex justify-center items-start pl-4 pr-6">
          <RangeSlider values={rangePriceValues} />
        </div>
        <div className="w-full my-5 p-2 md:text-white text-my-green text-xl font-montserrat font-light md:bg-my-green bg-white uppercase">
          Kategoria:
        </div>
        <div className="w-full flex justify-center items-center">
          {categories.map((el, i) => {
            return (
              <div key={el} className="flex items-center my-4 px-4">
                <input
                  id={el}
                  aria-describedby={`checkbox-1 ${i}`}
                  type="checkbox"
                  className="w-5 h-5 rounded-md accent-my-green cursor-pointer"
                  value={el}
                  onChange={(e) =>
                    dispatch(
                      setCategoryFilter(
                        e.target.checked
                          ? [...cateroryFilterValues, e.target.value]
                          : [
                              ...cateroryFilterValues.filter(
                                (el) => el !== e.target.value
                              ),
                            ]
                      )
                    )
                  }
                  checked={cateroryFilterValues.indexOf(el) >= 0}
                />
                <label
                  htmlFor={`checkbox-1 ${i}`}
                  className="ml-3 text-lg font-montserrat font-light  text-md uppercase md:text-my-dark-green text-white"
                >
                  {el}
                </label>
              </div>
            );
          })}
        </div>
      </motion.div>
    </motion.div>
  );
}
