import { Link } from "react-router-dom";
export default function Menu() {
  return (
    <ul className="w-full h-full overflow-hidden flex flex-col items-start md:justify-center justify-between uppercase font-montserrats 2xl:text-3xl md:text-lg text-sm md:font-light font-medium tracking-wide border-l-4  border-my-green md:pl-4 pl-2 md:ml-4 ml-2 transform duration-300">
      <li className="flex w-full text-my-dark-green02 ">
        <Link to={"/"}>Start</Link>
      </li>
      <li className="flex w-full text-my-dark-green02 ">
        <Link to={"/onas"}>O nas</Link>
      </li>
      <li className="flex w-full text-my-dark-green02 ">
        <Link to={"/"}>Sklep</Link>
      </li>
      <li className="flex w-full text-my-dark-green02 ">
        <Link to={"/kontakt"}> Kontakt</Link>
      </li>
    </ul>
  );
}
