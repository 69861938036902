import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { motion, AnimatePresence } from "framer-motion";

import OrderedProductListItem from "./OrderedProductListItem";

export default function OrderedProductList() {
  const navigate = useNavigate();
  const basketItems = useSelector((state) => state.cartSlice.items);

  useEffect(() => {
    basketItems.length < 1 && navigate("/");
  }, [basketItems]);

  return (
    <motion.div className="w-full flex flex-col text-sm lg:text-base">
      <motion.div className="w-full flex justify-between items-start py-4 px-4 rounded-t-3xl uppercase font-bold bg-my-green text-white">
        <div className="md:w-1/6 w-1/12"></div>
        <div className="w-2/6 text-left">Produkt</div>
        <div className="w-1/6 text-right">Ilość</div>
        <div className="w-1/6  text-right">Cena za sztukę</div>
        <div className="w-1/6  text-right">Razem</div>
      </motion.div>
      <motion.ul className="w-full py-2 flex flex-col justify-start items-center bg-white text-my-dark-green02">
        <AnimatePresence initial={false}>
          {basketItems.map((item) => (
            <OrderedProductListItem key={item.id} item={item} />
          ))}
        </AnimatePresence>
      </motion.ul>
    </motion.div>
  );
}
