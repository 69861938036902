import { configureStore } from "@reduxjs/toolkit";
import { apiSlice } from "../features/apiSlice";
import filtersSlice from "../features/filtersSlice";
import cartSlice from "../features/cartSlice";
import navigationScrollSlice from "../features/navigationScrollSlice";
import notificationsSlice from "../features/notificationsSlice";

export const store = configureStore({
  reducer: {
    [apiSlice.reducerPath]: apiSlice.reducer,
    filtersSlice,
    cartSlice,
    navigationScrollSlice,
    notificationsSlice,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(apiSlice.middleware),
});
