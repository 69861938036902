import { useSelector } from "react-redux";
import { usePrevious } from "../../../hooks/usePrevious";

import { motion, AnimatePresence } from "framer-motion";

export default function Receipt() {
  const totalAmount = useSelector((state) => state.cartSlice.totalAmount);
  let prev = usePrevious(totalAmount);
  let direction = totalAmount > prev ? 1 : -1;

  const variants = {
    enter: (direction) => ({
      y: direction * -20,
      opacity: 0,
      transition: { duration: 0.3, ease: "easeInOut" },
    }),
    center: {
      y: 0,
      opacity: 1,
      transition: { duration: 0.3, ease: "easeInOut" },
    },
    exit: (direction) => ({
      y: direction * 20,
      opacity: 0,
      transition: { duration: 0.3, ease: "easeInOut" },
    }),
  };

  return (
    <div className="md:w-1/3 w-full flex flex-col text-my-dark-green02 justify-start items-center">
      <div className="w-full  h-full flex justify-between items-start">
        <div className="w-1/3 p-2 grayscale">
          <img className="w-full" src="./images/logo.svg" alt="" />
        </div>
        <div className="w-1/3 h-full flex flex-col justify-end items-end">
          <div className="w-full flex justify-end text-lg uppercase py-1 text-right font-bold">
            <div className="relative w-full h-full overflow-hidden flex justify-end items-end ">
              <AnimatePresence custom={direction} initial={false}>
                <motion.div
                  key={totalAmount}
                  variants={variants}
                  custom={direction}
                  initial="enter"
                  animate="center"
                  exit="exit"
                  className="absolute top-0 right-0 w-full h-full flex justify-end items-start text-right overflow-hidden "
                >
                  {totalAmount}
                </motion.div>
              </AnimatePresence>{" "}
            </div>
            <div className="ml-2">PLN</div>
          </div>
          <div className="w-full  text-sm uppercase py-1 text-right  border-t border-t-gray-600 border-dotted">
            + przesyłka: 15 PLN
          </div>
        </div>
      </div>
      <div className="flex justify-end items-center w-full  text-2xl font-bold uppercase border-t-2 border-t-gray-600 border-dotted text-right">
        <div className="relative w-2/6 h-full overflow-hidden flex justify-end items-end ">
          <AnimatePresence custom={direction} initial={false}>
            <motion.div
              key={totalAmount}
              variants={variants}
              custom={direction}
              initial="enter"
              animate="center"
              exit="exit"
              className="absolute top-0 right-0 h-full overflow-hidden"
            >
              {totalAmount + 15}
            </motion.div>
          </AnimatePresence>
        </div>
        <div className="ml-2">PLN</div>
      </div>
    </div>
  );
}
