import OrderForm from "./OrderForm";
import OrderedProductList from "./OrderedProductList";
import { motion } from "framer-motion";

import Receipt from "./Receipt";

export default function Basket() {
  return (
    <motion.section className="w-full flex md:flex-row flex-col justify-center items-start px-4 pt-10 pb-40">
      <motion.div
        initial={{
          x: "-100px",
          opacity: 0,
          transition: { duration: 0.6, ease: "easeInOut" },
        }}
        animate={{
          x: 0,
          opacity: 1,
          transition: { duration: 0.6, ease: "easeInOut" },
        }}
        exit={{
          x: "-100px",
          opacity: 0,
          transition: { duration: 0.3, ease: "easeInOut" },
        }}
        className="flex flex-col justify-center items-center md:w-2/3 w-full md:mx-4 mb-6"
      >
        <div className="w-full text-4xl font-thin mb-6 text-my-dark-green02">
          KOSZYK:
        </div>
        <OrderedProductList />

        <motion.div className="flex md:flex-row flex-col justify-between w-full  bg-white border-t border-gray-300 px-4 py-8">
          <div className="lg:px-2 lg:w-1/3">
            <div className="p-4 bg-my-dark-green02 text-white rounded-full">
              <h1 className="ml-2 font-bold uppercase">Kupon Rabatowy</h1>
            </div>
            <div className="p-4">
              <p className="mb-4 italic">
                Jeśli posiadasz kupon upoważniający do zniżki, podaj go teraz!
              </p>
              <div className="justify-center md:flex">
                <form action="" method="POST">
                  <div className="flex items-center w-full h-13 pl-3  bg-gray-100 border rounded-full">
                    <input
                      type="coupon"
                      name="code"
                      id="coupon"
                      placeholder="Apply coupon"
                      value="90off"
                      readOnly
                      className="w-full bg-gray-100 outline-none appearance-none focus:outline-none active:outline-none"
                    />
                    <button
                      type="submit"
                      className="text-sm flex items-center px-3 py-1 text-white bg-my-green rounded-full outline-none md:px-4 hover:bg-my-dark-green02 transform duration-300 focus:outline-none active:outline-none"
                    >
                      <svg
                        aria-hidden="true"
                        data-prefix="fas"
                        data-icon="gift"
                        className="w-8 mx-2 fill-white"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 512 512"
                      >
                        <path d="M32 448c0 17.7 14.3 32 32 32h160V320H32v128zm256 32h160c17.7 0 32-14.3 32-32V320H288v160zm192-320h-42.1c6.2-12.1 10.1-25.5 10.1-40 0-48.5-39.5-88-88-88-41.6 0-68.5 21.3-103 68.3-34.5-47-61.4-68.3-103-68.3-48.5 0-88 39.5-88 88 0 14.5 3.8 27.9 10.1 40H32c-17.7 0-32 14.3-32 32v80c0 8.8 7.2 16 16 16h480c8.8 0 16-7.2 16-16v-80c0-17.7-14.3-32-32-32zm-326.1 0c-22.1 0-40-17.9-40-40s17.9-40 40-40c19.9 0 34.6 3.3 86.1 80h-86.1zm206.1 0h-86.1c51.4-76.5 65.7-80 86.1-80 22.1 0 40 17.9 40 40s-17.9 40-40 40z" />
                      </svg>
                      <span className="mr-2 text-sm font-bold text-white">
                        Wyślij
                      </span>
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>

          <Receipt />
        </motion.div>
      </motion.div>

      <motion.div className="flex flex-col md:w-1/3 w-full">
        <div className="w-full text-4xl font-thin mb-6 text-my-dark-green02">
          KASA:
        </div>
        <OrderForm />
      </motion.div>
    </motion.section>
  );
}
