import { useState, useEffect, useRef } from "react";
import { useParams, Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { addProduct } from "../../../features/cartSlice";

import { selectProductsById } from "../../../features/productSlice";
import Quantity from "../ProductList/Quantity";
import { usePrevious } from "../../../hooks/usePrevious";
import { motion, AnimatePresence } from "framer-motion";
import SEO from "../../SEO";
import ProductImagesGallery from "./ProductImagesGallery";
export default function Selected() {
  let { id } = useParams();

  const scollToRef = useRef();
  useEffect(() => {
    scollToRef.current.scrollIntoView({
      behavior: "smooth",
    });
  }, []);

  const dispatch = useDispatch();

  const productData = useSelector((state) => selectProductsById(state, id));
  console.log("productData ", productData);
  useEffect(() => {
    setData(productData);
  }, []);

  const [data, setData] = useState(null);

  const [quantity, setQuantity] = useState(1);

  let prev = usePrevious(quantity);
  let direction = quantity > prev ? 1 : -1;
  // const [imageShow, setImageShow] = useState(
  //   `http://phmed.pl/upload/${productData?.image[0]}`
  // );
  const variants = {
    enter: (direction) => ({
      y: direction * -30,
      opacity: 0,
      transition: { duration: 0.3, ease: "easeInOut" },
    }),
    center: {
      y: 0,
      opacity: 1,
      transition: { duration: 0.3, ease: "easeInOut" },
    },
    exit: (direction) => ({
      y: direction * 30,
      opacity: 0,
      transition: { duration: 0.3, ease: "easeInOut" },
    }),
  };
  console.log("data IMAGE", data?.image);
  return (
    <>
      <SEO
        title={`${data?.title}`}
        description={`${data?.description}`}
        name="Phmed."
        schema={`{
                  "@context": "https://schema.org",
                  "@type": "Product",
                  "description": "${data?.description}",
                  "name": "${data?.title}",
                  "image": "https://phmed.pl/pictures/${data?.image[0]}",
                  "offers": {
                    "@type": "Offer",
                    "price": "${data?.price}",,
                    "priceCurrency": "PLN"
                  },
                }`}
      />
      <motion.section
        ref={scollToRef}
        className="relative flex md:flex-row flex-col flex-wrap justify-start items-start content-start w-full md:min-h-screen p-4 pt-[85px] pb-24 "
      >
        <motion.div className="w-full flex justify-between items-center mb-6 text-xl text-my-green font-bold">
          <Link to={-1} className="flex">
            <svg
              enableBackground="new 0 0 15 26"
              height="26px"
              id="Layer_1"
              version="1.1"
              viewBox="0 0 15 26"
              width="15px"
              className="fill-my-green"
            >
              <polygon points="12.885,0.58 14.969,2.664 4.133,13.5 14.969,24.336 12.885,26.42 2.049,15.584 -0.035,13.5 " />
            </svg>{" "}
            &nbsp;&nbsp;SKLEP
          </Link>
          <Link to={-1}>
            <svg
              width="40px"
              height="40px"
              id="Layer_1"
              version="1.1"
              viewBox="0 0 512 512"
              className="fill-my-green"
            >
              <path d="M443.6,387.1L312.4,255.4l131.5-130c5.4-5.4,5.4-14.2,0-19.6l-37.4-37.6c-2.6-2.6-6.1-4-9.8-4c-3.7,0-7.2,1.5-9.8,4  L256,197.8L124.9,68.3c-2.6-2.6-6.1-4-9.8-4c-3.7,0-7.2,1.5-9.8,4L68,105.9c-5.4,5.4-5.4,14.2,0,19.6l131.5,130L68.4,387.1  c-2.6,2.6-4.1,6.1-4.1,9.8c0,3.7,1.4,7.2,4.1,9.8l37.4,37.6c2.7,2.7,6.2,4.1,9.8,4.1c3.5,0,7.1-1.3,9.8-4.1L256,313.1l130.7,131.1  c2.7,2.7,6.2,4.1,9.8,4.1c3.5,0,7.1-1.3,9.8-4.1l37.4-37.6c2.6-2.6,4.1-6.1,4.1-9.8C447.7,393.2,446.2,389.7,443.6,387.1z" />
            </svg>
          </Link>
        </motion.div>
        <motion.div
          initial={{ x: "-100px", opacity: 0 }}
          animate={{
            x: 0,
            opacity: 1,
            transition: { duration: 0.6, ease: "easeInOut" },
          }}
          exit={{
            x: "-100px",
            opacity: 0,
            transition: { duration: 0.3, ease: "easeInOut" },
          }}
          className="md:w-1/2 w-full flex items-centert justify-center"
        >
          <ProductImagesGallery images={[...productData.image]} />
        </motion.div>
        <motion.div
          initial={{ x: "100px", opacity: 0 }}
          animate={{
            x: 0,
            opacity: 1,
            transition: { duration: 0.6, ease: "easeInOut" },
          }}
          exit={{
            x: "100px",
            opacity: 0,
            transition: { duration: 0.3, ease: "easeInOut" },
          }}
          className="flex flex-col md:w-1/2 w-full justify-start items-start md:pl-16"
        >
          <h1 className="mb-2 leading-tight tracking-tight font-montserrat font-light text-my-dark-green02 text-2xl md:text-5xl uppercase">
            {data?.title}
          </h1>
          <p className="text-my-dark-green text-sm font-bold uppercase mb-2">
            {data?.category}
          </p>
          <div className="text-[#404040] font-light leading-[1.35] md:w-2/3 text-justify w-full mb-2">
            {data?.description}
          </div>
          <div
            className="flex   2xl:w-full md:w-11/12 w-full md:space-x-4 space-x-2 mt-4"
            itemProp="offers"
            itemScope
            itemType="https://schema.org/Offer"
          >
            <div
              className=" 2xl:w-1/12 md:w-1/6  w-1/6 bg-my-green flex flex-col justify-between items-center text-center  text-white font-bold text-xl"
              itemProp="price"
            >
              <div className="relative w-full h-1/2 flex justify-center items-center overflow-hidden">
                <AnimatePresence custom={direction}>
                  <motion.div
                    key={id + "_" + quantity}
                    variants={variants}
                    custom={direction}
                    initial="enter"
                    animate="center"
                    exit="exit"
                    className="absolute top-0 left-0 w-full h-full flex justify-center items-start overflow-hidden "
                  >
                    {data?.price * quantity}
                  </motion.div>
                </AnimatePresence>
              </div>

              <div className="w-full h-1/2 flex justify-center items-end ">
                PLN
              </div>
            </div>
            <Quantity
              quantity={quantity}
              setQuantity={setQuantity}
              classes="2xl:w-1/6 md:w-1/2 w-1/4 border-my-green text-my-green"
            />
            <button
              type="button"
              className="group flex justify-between items-center  px-6 py-2 font-semibold  bg-my-yellow text-my-dark-green   hover:text-white duration-300"
              onClick={() => dispatch(addProduct({ ...data, quantity }))}
            >
              <svg
                width="32px"
                height="32px"
                version="1.1"
                viewBox="0 0 512 512"
                className="fill-my-dark-green group-hover:fill-white transform duration-300"
              >
                <g id="PL_x5F_Cart_1_">
                  <path d="M441,416c0,13.8-11.2,25-25,25s-25-11.2-25-25s11.2-25,25-25S441,402.2,441,416z" />
                  <path d="M153,416c0,13.8-11.2,25-25,25s-25-11.2-25-25s11.2-25,25-25S153,402.2,153,416z" />
                  <path d="M127.9,96l-11.1-32H64v17h41.7l57.5,213.3c-32.4,11.3-59.9,37.9-65.3,73.1C96,379.1,96,384,96,384h352v-16.7H115.3   c4.7-31.6,38.8-58.1,74.1-62.5s243.3-34.2,243.3-34.2L448,96H127.9z" />
                </g>
              </svg>
              &nbsp; Dodaj do koszyka
            </button>
          </div>
        </motion.div>
      </motion.section>
    </>
  );
}
