import { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import {
  useGetAllSlidersQuery,
  selectAllSliders,
} from "../../features/sliderSlice";

import Loader from "../Loader";
import { AnimatePresence, motion } from "framer-motion";

export default function Slider() {
  const { error, isLoading } = useGetAllSlidersQuery();
  const productsData = useSelector(selectAllSliders);
  const [activeSlide, setActiveSlide] = useState(0);

  useEffect(() => {
    const timer = setTimeout(() => {
      activeSlide < productsData.length - 1
        ? setActiveSlide((prvState) => prvState + 1)
        : setActiveSlide(0);
    }, 6000);
    return () => {
      clearInterval(timer);
    };
  }, [activeSlide]);

  if (isLoading) return <Loader />;

  if (error) {
    return (
      <div className="text-my-dark-green text-8xl fopnt-bold font-bebas">
        ERROR!
      </div>
    );
  }
  return (
    <section className="w-full h-screen relative flex justify-center items-center overflow-hidden bg-gradient-radial from-white to-[#eee] bg-no-repeat bg-center">
      <div className="absolute top-0 left-0 bg-[url('./images/header_bg.png')] md:bg-[length:50%] bg-[length:133%] bg-no-repeat md:bg-[center_top]  bg-[center_top_17rem] w-full h-full" />
      <div className="relative lg:w-2/3 lg:h-5/6 md:w-2/3 md:h-1/5 w-full h-full ">
        <div className=" flex items-end justify-center absolute top-0 left-[50%] -translate-x-[50%] w-full  2xl:h-11/12 md:h-5/6 h-full font-black">
          <AnimatePresence>
            <motion.div
              key={productsData[activeSlide].id}
              initial={{ scale: 1, x: -100, opacity: 0 }}
              animate={{ scale: 1.65, x: 0, opacity: 1 }}
              exit={{ scale: 1, x: 100, opacity: 0 }}
              transition={{
                duration: 0.6,
                delay: 0.2,
                type: "tween",
                ease: "easeInOut",
              }}
              className="2xl:w-1/2 md:w-1/2 w-full font-bebas tracking-wider text-[#505050]/10 text-center absolute 2xl:top-15 md:top-16 top-44"
            >
              <p className="2xl:text-[3rem] md:text-[2rem] text-[1.5rem] leading-[1.5]">
                {productsData[activeSlide].title}
              </p>
              <p className="2xl:text-[8.25rem] md:text-[6rem] text-[4rem] leading-[0.8]">
                {productsData[activeSlide].title}
              </p>
              <p className="2xl:text-[3rem] md:text-[2rem] text-[1.5rem] leading-[1]">
                {" "}
                {productsData[activeSlide].title}
              </p>
            </motion.div>
            <motion.div
              key={productsData[activeSlide].title}
              initial={{ x: 100, opacity: 0 }}
              animate={{ x: 0, opacity: 1 }}
              exit={{ x: -100, opacity: 0 }}
              transition={{
                duration: 0.5,
                delay: 0.2,
                type: "tween",
                ease: "easeInOut",
              }}
              className="md:w-1/2 w-full font-bebas tracking-wider text-my-dark-green02 text-center absolute 2xl:top-7 md:top-8 top-40"
            >
              <p className="2xl:text-[3rem] md:text-[2rem] text-[1.5rem] leading-[1.5]">
                {productsData[activeSlide].title}
              </p>
              <p className="2xl:text-[8.25rem] md:text-[6rem] text-[4rem] leading-[0.8] ">
                {productsData[activeSlide].title}
              </p>
              <p className="2xl:text-[3rem] md:text-[2rem] text-[1.5rem] leading-[1]">
                {productsData[activeSlide].title}
              </p>
            </motion.div>
            <motion.img
              key={productsData[activeSlide].image}
              initial={{ y: -100, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              exit={{ y: 100, opacity: 0 }}
              transition={{ duration: 0.5, type: "tween", ease: "easeInOut" }}
              className=" absolute 2xl:w-[25%] md:w-[36%] w-3/4 z-10 md:-mb-13 mb-7"
              src={`./images/slider_${productsData[activeSlide].image}`}
              alt=""
            />
          </AnimatePresence>
        </div>
      </div>
      <div className="absolute h-50 w-full top-1/2">
        <button
          type="button"
          className="flex absolute top-0 md:left-5 justify-center items-center md:px-4 px-2 h-full cursor-pointer group focus:outline-none"
          data-carousel-prev
          onClick={() =>
            activeSlide > 0
              ? setActiveSlide((prvState) => prvState - 1)
              : setActiveSlide(productsData.length - 1)
          }
        >
          <span className="inline-flex justify-center items-center w-10 h-10 rounded-full sm:w-10 sm:h-10 bg-my-green  group-hover:bg-my-dark-green02 group-focus:ring-4 group-focus:ring-white  group-focus:outline-none transform duration-500">
            <svg
              className="w-5 h-5 text-white sm:w-6 sm:h-6 "
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M15 19l-7-7 7-7"
              ></path>
            </svg>
            <span className="hidden">Previous</span>
          </span>
        </button>
        <button
          type="button"
          className="flex absolute top-0 md:right-5 right-0 justify-center items-center md:px-4 px-2 h-full cursor-pointer group focus:outline-none"
          data-carousel-next
          onClick={() =>
            activeSlide < productsData.length - 1
              ? setActiveSlide((prvState) => prvState + 1)
              : setActiveSlide(0)
          }
        >
          <span className="inline-flex justify-center items-center w-10 h-10 rounded-full sm:w-10 sm:h-10 bg-my-green  group-hover:bg-my-dark-green02  group-focus:ring-4 group-focus:ring-white  group-focus:outline-none transform duration-500">
            <svg
              className="w-5 h-5 text-white sm:w-6 sm:h-6 "
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M9 5l7 7-7 7"
              ></path>
            </svg>
            <span className="hidden">Next</span>
          </span>
        </button>
      </div>
    </section>
  );
}
