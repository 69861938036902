import { useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { toggleHide } from "./features/navigationScrollSlice";
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";

import createScrollSnap from "scroll-snap";

import { AnimatePresence } from "framer-motion";
import Selected from "./components/Shop/SelectedProduct";
import Shop from "./components/Shop";
import Basket from "./components/Shop/Basket";
import Navigation from "./components/Navigation";
import Header from "./components/Header";
import Onas from "./components/Pages/Onas";
import Regulamin from "./components/Pages/Regulamin";
import Kontakt from "./components/Pages/Kontakt";
import Footer from "./components/Footer";
import Notifications from "./components/Notifications";
const AminatedRourers = () => {
  const location = useLocation();
  return (
    ////TODO: TUTAJ SPRAWDZAJ "IS IN VIEW" JEŚLI PATH ROŻNY OD /
    <AnimatePresence initial={false} mode="wait">
      <Routes location={location} key={location.pathname}>
        <Route path="/" element={<Shop />} />
        <Route path="/koszyk" element={<Basket />} />
        <Route path="/produkt/:id" element={<Selected />} />
        <Route path="/onas" element={<Onas />} />
        <Route path="/regulamin" element={<Regulamin />} />
        <Route path="/kontakt" element={<Kontakt />} />
      </Routes>
    </AnimatePresence>
  );
};

function App() {
  const snapContainer = useRef(null);
  const dispatch = useDispatch();
  // useEffect(() => {
  //   const element = snapContainer.current;
  //   const xxx = createScrollSnap(
  //     element,
  //     {
  //       snapDestinationY: "100%",
  //       lastSnapPointY: 3,
  //     },
  //     () => console.log("SNAP!")
  //   );
  // }, []);
  return (
    <>
      <Notifications />
      <div
        ref={snapContainer}
        id="app"
        className="w-full h-screen flex flex-col bg-[#eee] overflow-x-hidden"
        onScroll={(e) =>
          e.target.scrollTop < 150
            ? dispatch(toggleHide(false))
            : dispatch(toggleHide(true))
        }
      >
        <BrowserRouter>
          <HelmetProvider>
            <Navigation />
            <Header />
            <AminatedRourers />
            <Footer />
          </HelmetProvider>
        </BrowserRouter>
      </div>
    </>
  );
}

export default App;
