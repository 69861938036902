import { createSlice } from "@reduxjs/toolkit";

export const navigationScrollSlice = createSlice({
  name: "Navigation Scroll",
  initialState: {
    toggleHide: false,
  },
  reducers: {
    toggleHide: (state, action) => {
      state.toggleHide = action.payload;
    },
  },
});
export const { toggleHide } = navigationScrollSlice.actions;
export default navigationScrollSlice.reducer;
